import React, { useState, useRef, useEffect } from 'react';
import './App.css'
import { MdCloudDownload } from 'react-icons/md';
import api from './services/api'
import ProgressBar from './components/progressbar'
import BeatLoader from "react-spinners/BeatLoader";
import ReactGA from 'react-ga4';
var responseObj: any = {}

function App() {
  const [link, setLink] = useState('')
  const [downloadedLink, setDownloadedLink] = useState({} as any)
  const [warnText, setWarnText] = useState('')
  const [completed, setCompleted] = useState(0)
  const [thumb, setThumb] = useState('')
  const [duration, setDuration] = useState('')
  const [title, setTitle] = useState('')
  const [info, setInfo] = useState('')
  const [bitrate, setBitrate] = useState('')
  const [disabledButton, setDisabledButton] = useState(Boolean)
  const [disabledInput, setDisabledInput] = useState(Boolean)

  const warningRef = useRef<HTMLDivElement>(null)
  const infoRef = useRef<HTMLDivElement>(null)
  const downloadRef = useRef<HTMLDivElement>(null)
  const convertRef = useRef<HTMLDivElement>(null)
  const loadingRef = useRef<HTMLDivElement>(null)

  const banner = useRef<HTMLDivElement>(null) as React.MutableRefObject<HTMLIFrameElement>;
  const banner2 = useRef<HTMLDivElement>(null) as React.MutableRefObject<HTMLIFrameElement>;
  const bannerLeft = useRef<HTMLDivElement>(null) as React.MutableRefObject<HTMLIFrameElement>;
  const bannerRight = useRef<HTMLDivElement>(null) as React.MutableRefObject<HTMLIFrameElement>;
  const linkUnder = useRef<HTMLDivElement>(null) as React.MutableRefObject<HTMLIFrameElement>;

  const linkUnderAli = useRef<HTMLDivElement>(null) as React.MutableRefObject<HTMLIFrameElement>;
  const linksAli = ["https://www.profitablegatecpm.com/vcqtzj1cch?key=e40a21c9e4a1e02c71855026478d4dbe",
    "https://cheeryconcern.com/b.3QV/0mPc3/pwvLbjmxVRJYZmD/0e0UOOTVYo5-NHj/ITydLjTRQi5dNkjKko2/M/jSMV"];
  const [selectedLinkAli, setSelectedLinkAli] = useState<string | undefined>(undefined);

  const atOptions = {
    key: '17f8fb06338f50a67d4388c6009df10c',
    format: 'iframe',
    height: 250,
    width: 320,
    params: {},
    id: 'banner-1'
  }
  const at2Options = {
    key: 'baba4a913390e5aedb21f20879359322',
    format: 'iframe',
    height: 250,
    width: 300,
    params: {},
    id: 'banner-2'
  }
  const atLeftOptions = {
    key: '666e5f3c9c9bd95662440982457bcc8b',
    format: 'iframe',
    height: 600,
    width: 160,
    params: {},
    id: 'banner-3'
  }
  const atRightOptions = {
    key: '666e5f3c9c9bd95662440982457bcc8b',
    format: 'iframe',
    height: 600,
    width: 160,
    params: {},
    id: 'banner-4'
  }
  useEffect(() => {
    linkUnder.current?.style.setProperty('display', 'none', 'important')
    linkUnderAli.current?.style.setProperty('display', 'none', 'important')
    ReactGA.initialize('G-15ENT7GFJX');
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    if (banner.current && !banner.current.firstChild) {
      const conf = document.createElement('script')
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = `//www.topcreativeformat.com/${atOptions.key}/invoke.js`
      conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`
      banner.current.append(conf)
      banner.current.append(script)

      setTimeout(() => {
        if (banner2.current && !banner2.current.firstChild) {
          const conf2 = document.createElement('script')
          const script2 = document.createElement('script')
          script2.type = 'text/javascript'
          script2.src = `//www.topcreativeformat.comm/${at2Options.key}/invoke.js`
          conf2.innerHTML = `atOptions = ${JSON.stringify(at2Options)}`
          banner2.current.append(conf2)
          banner2.current.append(script2)
          window.frames[0].document.body.children[1].children[0].setAttribute("id", "banner")
        }
        setTimeout(() => {
          if (bannerLeft.current && !bannerLeft.current.firstChild) {
            const confLeft = document.createElement('script')
            const scriptLeft = document.createElement('script')
            scriptLeft.type = 'text/javascript'
            scriptLeft.src = `//www.topcreativeformat.com/${atLeftOptions.key}/invoke.js`
            confLeft.innerHTML = `atOptions = ${JSON.stringify(atLeftOptions)}`
            bannerLeft.current.append(confLeft)
            bannerLeft.current.append(scriptLeft)
            window.frames[0].document.body.children[1].children[0].setAttribute("id", "banner")
          }
          setTimeout(() => {
            if (bannerRight.current && !bannerRight.current.firstChild) {
              const confRight = document.createElement('script')
              const scriptRight = document.createElement('script')
              scriptRight.type = 'text/javascript'
              scriptRight.src = `//www.topcreativeformat.com/${atRightOptions.key}/invoke.js`
              confRight.innerHTML = `atOptions = ${JSON.stringify(atRightOptions)}`
              bannerRight.current.append(confRight)
              bannerRight.current.append(scriptRight)
              window.frames[0].document.body.children[1].children[0].setAttribute("id", "banner")
            }
          }, 1000);
        }, 2000);

      }, 2000);

    }
  },)
  function validURL(str: string) {
    var pattern = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/\S*(?:(?:\/e(?:mbed))?\/|watch\?(?:\S*?&?v=))|youtu\.be\/)([a-zA-Z0-9_-]{6,11})/
    return (str.match(pattern)) ? true : false;
  }

  async function handleVideoDetail(e: any) {

    e.preventDefault();
    const videoUrl = e.target.value

    if (validURL(videoUrl) === true) {
      ReactGA.event({
        category: 'Button',
        action: 'info',
        label: 'info'
      });
      var response = await api.get('/info', { params: { url: videoUrl } })
      if (response.data.code === 0) {

        setCompleted(0);
        setTimeout(function () {
          downloadRef.current?.setAttribute(
            "style", "display:none;"
          )
        }, 900)

        setTimeout(function () {
          loadingRef.current?.setAttribute(
            "style", "display:none;"
          )
        }, 300)

        infoRef.current?.setAttribute(
          "style", "opacity:0;"
        )
        setTimeout(function () {
          infoRef.current?.setAttribute(
            "style", "opacity:1;"
          )
          setTitle(response.data.title)
          setThumb(response.data.thumbnail)
          setDuration(response.data.duration)

        }, 600)
        setTimeout(function () {
          convertRef.current?.setAttribute(
            "style", "display:flex;"
          )
        }, 900)
        setDisabledButton(false)
        setDisabledInput(false)

        responseObj = response
      } else {
        setWarnText('Check youtube url and try again.')
        setTimeout(function () {
          convertRef.current?.setAttribute(
            "style", "display:none;"
          )
        })
        infoRef.current?.setAttribute(
          "style", "opacity:0;"
        )
        warningRef.current?.setAttribute(
          "style", "opacity:1;"
        )
        setTimeout(
          () => warningRef.current?.setAttribute(
            "style", "opacity:0;"
          ),
          3900
        )
      }
    } else {
      setWarnText('Please insert a valid url.')
      setTimeout(function () {
        convertRef.current?.setAttribute(
          "style", "display:none;"
        )
      }, 300)
      warningRef.current?.setAttribute(
        "style", "opacity:1;"
      )
      setTimeout(
        () => warningRef.current?.setAttribute(
          "style", "opacity:0;"
        ),
        4000
      )
    }
  }

  async function handleDownload(e: any) {
    setDownloadedLink({ ...downloadedLink, link })

    if (downloadedLink.link !== link) {
      e.preventDefault();
      //const bannerIframe = window.frames[0].document.getElementById("banner")
      //linkUnder.current?.click()
      linkUnderAli.current?.click()
      //bannerIframe?.click()
      ReactGA.event({
        category: 'Button',
        action: 'download_ads',
        label: 'download_ads'
      });

    }

    ReactGA.event({
      category: 'Button',
      action: 'download',
      label: 'download'
    });

    return true
  }

  async function handleSubmit(e: any) {

    e.preventDefault();
    const videoUrl = e.target[0].value


    let mp3config = {
      params: { url: videoUrl + "&flag=convert" },
      onDownloadProgress: function (progressEvent: any) {
        let percentCompleted = Math.floor((progressEvent.loaded) / progressEvent.total);
        setCompleted(percentCompleted);
        setDisabledInput(false)
      }
    }
    setDisabledButton(true)
    setDisabledInput(true)
    setTimeout(function () {
      loadingRef.current?.setAttribute(
        "style", "display:flex;"
      )
    }, 300)

    if (validURL(videoUrl) === true) {
      var randomIndex = Math.floor(Math.random() * linksAli.length);
      setSelectedLinkAli(linksAli[randomIndex]);
      ReactGA.event({
        category: 'Button',
        action: 'convert',
        label: 'convert'
      });
      const response = await api.get('/mp3', mp3config)
      if (response.data.code === 0) {
        var responseInfo = responseObj
        const validateVideo = responseInfo.data.duration || response.data.duration

        if (validateVideo <= 1816) {
          const mp3Response = {
            Name: responseInfo.data.title + ".mp3",
            BitRate: "256",
          }
          setBitrate(mp3Response.BitRate)
          setInfo(mp3Response.Name)
          setTimeout(function () {
            loadingRef.current?.setAttribute(
              "style", "display:none;"
            )
          }, 300)

          setTimeout(async function () {
            let mp3status = {
              params: { url: videoUrl + "&flag=status" }
            }
            var percents = 0;
            const progresssBar = setInterval(() => {
              percents++;
              setCompleted(percents);
            }, 1500)

            const statusPromise = new Promise((resolve, reject) => {
              var counter = 0;
              const tryRequest = () => {
                const checkStatus = api.get('/mp3', mp3status)
                counter++;
                checkStatus.then((response: any) => {
                  if (response.data.data.status === "done") {
                    resolve(response.data.data)
                  } else {
                    reject(response.data)
                  }
                }).catch((error: any) => {
                  if (error.code === "ECONNABORTED") {
                    if (counter < 12) {
                      tryRequest()
                    } else {
                      reject(error)
                    }
                  }
                })
              }
              tryRequest()
            })


            statusPromise.then((response: any) => {
              clearInterval(progresssBar)
              setCompleted(100);
              downloadRef.current?.setAttribute(
                "style", "display:flex;"
              )
            }).catch((error: any) => {
              clearInterval(progresssBar)
              setCompleted(0)
              setWarnText('Server is busy try again later. Sorry ;(')
              setTimeout(function () {
                loadingRef.current?.setAttribute(
                  "style", "display:none;"
                )
              }, 300)
              warningRef.current?.setAttribute(
                "style", "opacity:1;"
              )
              setTimeout(
                () => warningRef.current?.setAttribute(
                  "style", "opacity:0;"
                ),
                3900
              )
            })
          }, 1000)
          setLink('https://shikamaru.snapmp3.me/download/' + responseInfo.data.name)

        } else {
          setCompleted(0);
          setWarnText('Maximum length video is 30 minutes.')
          warningRef.current?.setAttribute(
            "style", "opacity:1;"
          )
          setTimeout(
            () => warningRef.current?.setAttribute(
              "style", "opacity:0;"
            ),
            3900
          )
        }
      } else {
        setCompleted(0)
        setWarnText('Server is busy try again later. Sorry ;(')
        setTimeout(function () {
          loadingRef.current?.setAttribute(
            "style", "display:none;"
          )
        }, 300)
        warningRef.current?.setAttribute(
          "style", "opacity:1;"
        )
        setTimeout(
          () => warningRef.current?.setAttribute(
            "style", "opacity:0;"
          ),
          3900
        )
      }
    } else {
      setWarnText('Please insert a valid url.')
      setTimeout(function () {
        convertRef.current?.setAttribute(
          "style", "display:none;"
        )
      }, 300)
      warningRef.current?.setAttribute(
        "style", "opacity:1;"
      )
      setTimeout(
        () => warningRef.current?.setAttribute(
          "style", "opacity:0;"
        ),
        3900
      )
      setTimeout(function () {
        loadingRef.current?.setAttribute(
          "style", "display:none;"
        )
      }, 300)
    }
  }

  const handleKeyDown = (event, callback) => {
    if (event.key === 'Enter' && event.shiftKey === false) {
      event.preventDefault();
    }
  };
  return (
    <div className="App">
      <div id="container">
        <div id="advertising-left">
          <div className="mx-2 my-5" ref={bannerLeft}></div>
        </div>
        <div id="form-container">
          <div id="title-form">
            <h2 id="slung">SnapMp3 </h2>
            <p >Snap Video From Youtube to MP3 File.</p>
            <h2><MdCloudDownload /></h2>
          </div>

          <form id="form" onSubmit={handleSubmit} onKeyDown={e => { handleKeyDown(e, handleSubmit) }}>
            <input
              onChange={handleVideoDetail}
              required
              type="text"
              placeholder="Paste url of a YouTube video"
              disabled={disabledInput}
            />
            <p>Maximum length video is 30 minutes.</p>
            <div
              id="warning"
              ref={warningRef}
            >
              {warnText}
            </div>
            <div
              id="button-convert"
              ref={convertRef}
            >
              <button
                disabled={disabledButton}
              >
                Convert Video</button>
            </div>
          </form>
          <div id="infoVideo"
            ref={infoRef}>
            <div id="infoTitle"><p>{title}</p></div>
            <div id="infoThumb"><img src={thumb} alt="" /></div>
            <ProgressBar bgColor={"#e55757"} completed={completed} />
            <div id="loading" ref={loadingRef}>
              <BeatLoader

                size={20}
                color={"#f14e56"} />
            </div>
            <div id="videoDownload" ref={downloadRef}>
              <div id="button">
                <a href={link} target='_blank' rel="noreferrer"><button onClick={handleDownload}>Download Mp3</button></a>
              </div>
              <div id="detailVideo">
                <p>{info}</p>
                <p>Bitrate: {bitrate} Kbps</p>
                <p>Duration: {duration} Seconds</p>
              </div>
            </div>
          </div>
          <div id="form-faq">
            <div id="advertising">
              <div className="mx-2 my-5 border border-gray-200 justify-center items-center text-white text-center" ref={banner}></div>
            </div>
            <div id="faq">
              <h2>SnapMp3 FAQ 📋</h2>
              <p>How to convert video to mp3?</p>
              <p>💯 Copy URL video you want to convert.</p>
              <p>💫 Paste URL into the field.</p>
              <p>💦 Click on Convert Button and Relax.</p>
              <p>✅ When it's done Download your Mp3 archive and have fun.</p>
            </div>
            <div id="advertising">
              <div className="mx-2 my-5 border border-gray-200 justify-center items-center text-white text-center" ref={banner2}></div>
            </div>
          </div>
        </div>
        <div id="advertising-right">
          <div className="mx-2 my-5" ref={bannerRight}></div>
        </div>
        <div id="advertising">
          <a ref={linkUnderAli} href={selectedLinkAli} rel="noreferrer" target='_blank'>sample</a>
          <a ref={linkUnder} href='https://nifty-transportation.com/ba3.Vg0kPN3bpYvdbHmxVTJTZEDX0/0SOATMYF5/NXjiIUywLQTlQr5xN-j/ky2GMEj/MI' rel="noreferrer" target='_blank'>sample</a>
        </div>

      </div>
      <div id='main-footer'>
      </div>
      <div id='main-footer'>
        <h4>SnapMp3</h4>&nbsp;<p>- Snap and Relax ;D</p>
      </div>
    </div>
  );
}

export default App;
